<template>
  <div class="position-relative">
    <div v-if="banner.html" v-html="banner.html" class="banner-html" :class="banner.placement == 'ONBOARDING' ? 'p-3 text-center banner-html-onboarding' : ''"/>
    <container :is="banner.link ? 'a' : 'fragment'" :href="banner.link" target="_new">
      <div :class="banner.placement == 'ONBOARDING' ? 'text-center' : ''">
        <img v-if="banner.picture.full" :src="banner.picture.full" />
        <div v-if="banner.iconlist" class="text-left container">
          <div class="row align-items-center justify-content-center py-2 iconlist">
            <div class="col-md-8">
              <div v-for="(line, i) in banner.iconlist" class="line d-flex py-2 align-items-center">
                <div class="icon text-center">
                  <i :class="iconClasses[i] + ' ' + line.attributes.icon + ' icon-2x'" />
                </div>
                <div class="text">
                  {{ line.attributes.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img v-if="banner.imglink && banner.imglink != ''" :src="banner.imglink" />
      </div>
    </container>
    <div class="button-wrapper text-center py-3" v-if="banner.placement == 'ONBOARDING'">
      <a class="border-white px-3 py-2 btn btn-success text-white" @click="addOnboardingToUser(); closeModal('banner-onboarding-' + banner.id)">
        <span v-if="banner.buttontext">
          {{ banner.buttontext }}
        </span>
        <span v-else>
          {{ $t('common.onboarding_close') }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
/**
 * @group Core
 * Display Banners
 */

import { USER_UPDATE } from '../../store/mutations-types'

export default {
  name: 'Banners',
  tag: 'el-banners',
  props: ['banners'],
  data () {
    return {
      iconClasses: [
          'text-info',
          'text-success',
          'text-yellow',
          'text-primary',
          'text-danger',
          'text-secondary',
        ],
    }
  },
  methods: {
    randomNumber () {
      return Math.floor(Math.random() * Math.floor(this.banners.length));
    },
    addOnboardingToUser () {
      let metadata = {...this.getCurrentUser.metadata}

      if (!metadata.onboarding || !Array.isArray(metadata.onboarding)) {
        metadata.onboarding = new Array()
      }

      if (this.banners[0].page == '*') {
        metadata.onboarding.push(this.banners[0].page)
      } else {
        metadata.onboarding.push(this.currentRouteName)
      }

      this.$store.dispatch('auth/' + USER_UPDATE, {
        user_id: this.getCurrentUser.id,
        metadata: metadata,
      })
    }
  },
  computed: {
    banner () {
      return this.banners[this.randomNumber()]
    },
    getCurrentUser () {
      return this.$store.getters['auth/getProfile']
    },
  }
}
</script>

<style scoped lang="scss">
.banner-html-onboarding {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  ::v-deep {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      font-weight: 500;
    }
  }
}
img {
  max-width: 100%;
}
.button-wrapper {
  // position: absolute;
  // width: 100%;
  // left: 0;
  // bottom: 0;
  .btn {
    font-size: 1.2em;
  }
}
.iconlist {
  .line {
    .text {
      font-size: 1.2em;
    }
    .icon-2x {
      width: 70px;
    }
  }
}
</style>
