<template>

  <div
    class="checklist-wrapper"
    v-if="!completedOnMount && currentUserClonedData"
    :class="{
      'mounted': isVisible,
      'closed': closed,
    }"
  >
    <div class="bg-white text-dark rounded-2x py-1 px-2 py-md-2 px-md-3 rounded-2x mb-3 mr-3 mb-md-5 mr-md-5 shadow border border-info">
      <div class="d-flex mb-1 justify-content-between align-items-top cursor-pointer" @click="closed = !closed">
        <h5 class="m-0 text-info font-weight-bold" v-if="title">{{ title }}</h5>
        <i class="py-1 icon-arrow-down12 ml-1 text-grey" v-if="!closed" />
        <i class="py-1 icon-arrow-up12 ml-1 text-grey" v-else />
      </div>
      <div class="small text-grey">{{ $t('banners.checklist.help') }}</div>
      <div class="rounded progressbar my-2">
        <div class="rounded pb-1 bg-success progressinner" :style="'width: ' + progressPct + '%;'" />
      </div>
      <div
        v-for="item in checklist"
        :key="item.key"
        class="d-flex align-items-top py-1"
        :class="{'done' : currentUserClonedData.metadata && currentUserClonedData.metadata.checklist && currentUserClonedData.metadata.checklist.includes(item.key)}"
      >
        <div
          :class="loading ? 'cursor-wait' : 'cursor-pointer'"
          @mouseenter="idHover = item.key"
          @mouseleave="idHover = null"
          @click="currentUserClonedData.metadata && currentUserClonedData.metadata.checklist && currentUserClonedData.metadata.checklist.includes(item.key) ? removeChecklistItemFromUser(item.key) : addChecklistItemToUser(item.key)"
        >
          <i
            v-if="currentUserClonedData.metadata && currentUserClonedData.metadata.checklist && currentUserClonedData.metadata.checklist.includes(item.key)"
            class="icon-checkbox-checked2 mr-1 text-success"
          />
          <i
            v-else
            class="icon-checkbox-unchecked2 mr-1 text-grey"
            :class="{'text-success': idHover == item.key}"
          />
        </div>
        <div class="checklist-item" v-html="item.attributes.item"></div>
      </div>
    </div>
  </div>

</template>

<script>
import { USER_UPDATE } from '../../store/mutations-types'
import _ from 'lodash'

/**
 * @group Core
 * Display checklist
 */
export default {
  tag: 'el-banner-checklist',
  name: 'BannerChecklist',
  props: {
    // The checklist title
    title: {
      type: String,
    },
    // The checklist content
    checklist: {
      type: [Object, Array],
      required: true,
    },
  },
  data () {
    return {
      currentUserClonedData: null,
      idHover: null,
      closed: true,
      loading: false,
      loadingTime: 500,
      isVisible: false,
      completedOnMount: false,
    }
  },
  mounted () {
    this.currentUserClonedData = _.cloneDeep(this.getCurrentUser)

    if (this.ifChecklistCompleted) {
      this.completedOnMount = true
    }
    setTimeout(() => {
      if (!this.ifChecklistCompleted) {
        this.isVisible = true
      }
    }, 1000)
  },
  methods: {
    removeChecklistItemFromUser (itemId) {
      if (this.loading) {
        return
      }
      this.loading = true
      let metadata = this.currentUserClonedData.metadata

      if (!metadata.checklist || !Array.isArray(metadata.checklist)) {
        return
      }

      metadata.checklist = _.filter(metadata.checklist, (value) => {
          return value != itemId
      })

      setTimeout(() => {
        this.loading = false
      }, this.loadingTime)

      this.$store.dispatch('auth/' + USER_UPDATE, {
        user_id: this.getCurrentUser.id,
        metadata: metadata,
      }).then(() => {
      })
    },
    addChecklistItemToUser (itemId) {
      if (this.loading) {
        return
      }
      this.loading = true
      let metadata = this.currentUserClonedData.metadata

      if (!metadata.checklist || !Array.isArray(metadata.checklist)) {
        metadata.checklist = new Array()
      }

      metadata.checklist.push(itemId)

      setTimeout(() => {
        this.loading = false
      }, this.loadingTime)

      this.$store.dispatch('auth/' + USER_UPDATE, {
        user_id: this.getCurrentUser.id,
        metadata: metadata,
      }).then(() => {
      })
    },
  },
  computed: {
    getCurrentUser () {
      return this.$store.getters['auth/getProfile']
    },
    ifChecklistCompleted () {
      return this.progressPct >= 100
    },
    progressPct () {
      if (this.totalItems < 1) {
        return 0
      }
      return this.completedItems * 100 / this.totalItems
    },
    totalItems () {
      if (!this.checklist || !Array.isArray(this.checklist)) {
        return 0
      }
      return this.checklist.length
    },
    completedItems () {
      let completedItems = 0
      if (this.checklist && Array.isArray(this.checklist)) {
        _.forEach(this.checklist, (item) => {
          if (this.currentUserClonedData.metadata && this.currentUserClonedData.metadata.checklist && this.currentUserClonedData.metadata.checklist.includes(item.key)) {
            completedItems++
          }
        })
      }
      return completedItems
    },
  }
}
</script>

<style scoped lang="scss">
.checklist-wrapper {
  transition: all .4s ease;
  max-width: 320px;
  &.mounted {
    bottom: 0;
    opacity: 1;
  }
  position: fixed;
  bottom: 30px;
  opacity: 0;
  right: 0;
  &.closed {
    top: calc(100% - 70px);
  }
  .progressbar {
    background: #F0F0F0;
    .progressinner {
      transition: all .2s ease;
    }
  }
  .done {
    text-decoration: line-through rgba(black, .5);

  }
  .checklist-item {
    ::v-deep p {
      margin: 0;
    }
  }
  > div {
    border-width: 5px !important;
  }
}
.rounded-2x {
  border-radius: 0.5rem !important;
}
</style>
