<template>

  <div>
    <div class="mb-2 editor mt-1 w-100 p-1 pb-2" v-html="data"/>
  </div>

</template>

<script>

export default {
  name: 'process-template-element-editor',
  props: ['data', 'classColorName'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
::v-deep .editor {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
