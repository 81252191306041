<template>

  <div>
    <div class="modal fade" :id="id" role="dialog" :aria-labelledby="id" aria-hidden="true">
      <div :class="'modal-dialog modal-dialog-centered modal-dialog-zoom ' + modalSize" role="document">
        <div class="modal-content">
          <div class="modal-header bg-greylight d-flex align-items-center border-0" :style="color ? 'border-bottom-width: 3px; border-color: ' + color + ';' : ''" v-if="!noTitle">
            <div>
              <small :style="color ? 'color: ' + color + ';' : 'color:gray;'" class="modal-subtitle font-weight-bold text-uppercase" v-if="subtitle">{{ subtitle }}</small>
              <el-card-title :title="title"/>
            </div>
            <button type="button" class="close p-3 text-dark" data-dismiss="modal" :aria-label="$t('common.close')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" :class="bodyClasses">
            <!-- Modal body -->
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

/**
 * @group Core
 * Display bootstrap modal
 */
export default {
  tag: 'el-modal',
  name: 'Modal',
  props: {
    // Identifier
    id: {
      type: String,
      required: true
    },
    // Modal title
    title: {
      type: String
    },
    // Modal subtitle
    subtitle: {
      type: String
    },
    // Modal color
    color: {
      type: String
    },
    // Modal's width : sm, lg ..
    size: {
      type: String,
      default: 'md'
    },
    // Modal's width : sm, lg ..
    bodyClasses: {
      type: String,
      default: ''
    },
    // Hide modal title
    noTitle: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
    }
  },
  mounted () {
    $('.modal').on('hidden.bs.modal',() => {
      this.$store.commit('global/openModal', null)
    })
  },
  computed: {
    // @vuese
    // Format modal size to be added to classes
    modalSize () {
      return 'modal-' + this.size
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep img {
  max-height: 70vh;
}
.modal-fullwidth {
  max-width: none;
  width: 100vw;
  min-height: 100vh;
  margin-top: 52px;
  margin-bottom: 0;
  > .modal-content {
    min-height: calc(100vh);
  }
}
</style>
