<template>
  <div>
    <div
      :class="{
        [`rounded py-2 px-3 text-left alert alert-styled-left alert-dismissible border-top-0 border-bottom-0 border-right-0 alert-${type}`]: true,
        [`text-${type}`]: type !== 'info',
      }">
        <h6 v-if="title" class="font-weight-bold mb-1">{{ title }}</h6>
        <div v-if="html" v-html="html"></div>
        <!-- Alert content -->
        <div class="alert-content py-1">
          <slot></slot>
        </div>
    </div>
  </div>
</template>

<script>
/**
 * @group Core
 * Display bootstrap alert messages
 * https://getbootstrap.com/docs/4.0/components/alerts/
 */
export default {
  name: 'Alert',
  tag: 'el-alert',
  props: {
    type: {
      // `'danger'` / `'success'` / `'info'` / `'warning'` / `'help'`
      type: String,
      required: true,
      default: 'help',
    },
    html: {
      type: String
    },
    title: {
      type: String
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
.tokens.alert[class*=alert-styled-]:after {
  content: "\eece";
}
.alert-content {
  ::v-deep p:last-child {
    margin-bottom: 0;
  }
}
.alert-help {
  border-left-color: rgba(black, 0.05);
  background: rgba(black, 0.05);
  color: #353535;
  &:after {
    color: inherit;
    content: '\e9bc';
  }
}
</style>
