import { render, staticRenderFns } from "./_Support.vue?vue&type=template&id=7100832c&scoped=true&"
import script from "./_Support.vue?vue&type=script&lang=js&"
export * from "./_Support.vue?vue&type=script&lang=js&"
import style0 from "./_Support.vue?vue&type=style&index=0&id=7100832c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7100832c",
  null
  
)

export default component.exports